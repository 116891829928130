import { useEffect, useState } from "react"
import { Navigate, useNavigate, useLocation } from "react-router-dom"
import Header from "../../components/Header"
import { getItem } from "../../helpers"
import { useSelector } from "react-redux"
import { UnauthorisedAccess } from "../../components/ErrorPages/UnauthorisedAccess"
import { PageNotFound } from "../../components/ErrorPages/PageNotFound"

function checkIsUserValid(userInfo, userRoles, path) {
    const pathSegments = path.split("/").filter(Boolean)
    const modulePath = pathSegments?.[0]
    const subModulePath = pathSegments?.[1]
    const allowedModules = Object.keys(userRoles)

    if (path === "/" || modulePath === "dashboard") return true
    if (modulePath === "charger-management") {
        if (allowedModules?.includes("charger-management")) {
            if (!userRoles?.["charger-management"]?.view) return false
            if (subModulePath) {
                let currentModule = userRoles?.["charger-management"]
                switch (subModulePath) {
                    case "overview":
                        return currentModule?.overview?.view || false
                    case "all-chargers":
                        return currentModule?.charger?.view || false
                    case "charger-profile": //no permission
                        return currentModule?.charger?.view || false
                    case "price-history":
                        return currentModule?.charger?.["price-history"] || false
                    case "all-stations":
                        return currentModule?.stations?.view || false
                    case "station-profile":
                        return currentModule?.stations?.view || false
                    case "charging-station-log":
                        return currentModule?.["charging-station-logs"]?.view || false
                    case "charging-station-map":
                        return currentModule?.["station-map"]?.view || false
                    case "charger-bulk-actions":
                        return currentModule?.charger?.["bulk-action"] || false
                    case "load-management":
                        return currentModule?.["Load Management"]?.view || false
                    case "hub-profile": //no permission
                        return currentModule?.["Load Management"]?.view || false
                    default:
                        return "pageNotFound"
                }
            } else return true
        } else return false
    }
    if (modulePath === "user-management") {
        if (allowedModules?.includes("user-management")) {
            if (!userRoles?.["user-management"]?.view) return false
            if (subModulePath) {
                let currentModule = userRoles?.["user-management"]
                switch (subModulePath) {
                    case "overview":
                        return currentModule?.overview?.view || false
                    case "all-evowners":
                        return currentModule?.["ev-owners"]?.view || false
                    case "administrator":
                        return currentModule?.["admins"]?.view || false
                    default:
                        return "pageNotFound"
                }
            } else return true
        } else {
            return false
        }
    }
    if (modulePath === "company-management") {
        if (allowedModules?.includes("company-management")) {
            if (!userRoles?.["company-management"]?.view) return false
            if (subModulePath) {
                let currentModule = userRoles?.["company-management"]
                switch (subModulePath) {
                    case "company-details":
                        return currentModule?.["Company Details"]?.view || false
                    case "cpo":
                        return currentModule?.CPO?.view || false
                    case "client":
                        return currentModule?.clients?.view || false
                    default:
                        return "pageNotFound"
                }
            } else return true
        } else {
            return false
        }
    }
    if (modulePath === "billing") {
        if (allowedModules?.includes("billings-and-payments")) {
            if (!userRoles?.["billings-and-payments"]?.view) return false
            if (subModulePath) {
                let currentModule = userRoles?.["billings-and-payments"]
                switch (subModulePath) {
                    case "overview":
                        return currentModule?.overview?.view || false
                    case "charging-sessions":
                        return currentModule?.["charging-session"]?.view || false
                    case "user-payments":
                        return (
                            (currentModule?.["user-payments"]?.view &&
                                (userInfo.companyGroup === "marketplace" ||
                                    userInfo.companyGroup === "eMSP" ||
                                    userInfo.companyGroup === "CPO_eMSP")) ||
                            false
                        )
                    case "company-payments":
                        return (
                            (currentModule?.["company-payments"]?.view &&
                                (userInfo.companyGroup === "CPO_eMSP" ||
                                    userInfo.companyGroup === "eMSP" ||
                                    userInfo.companyGroup === "Fleet" ||
                                    userInfo.companyGroup === "marketplace") &&
                                parseInt(userInfo?.country_id) === 101) ||
                            false
                        )
                    case "company-payouts":
                        return (
                            (currentModule?.["company-payouts"]?.view &&
                                parseInt(userInfo?.country_id) === 101 &&
                                (userInfo?.companyGroup !== "Fleet" || userInfo?.companyGroup !== "eMSP")) ||
                            false
                        )
                    case "client-details":
                        return (
                            (currentModule?.["company-payouts"]?.view &&
                                parseInt(userInfo?.country_id) === 101 &&
                                (userInfo?.companyGroup !== "Fleet" || userInfo?.companyGroup !== "eMSP")) ||
                            false
                        )
                    case "charger-wise-utlization":
                        return (
                            (currentModule?.["company-payouts"]?.view &&
                                parseInt(userInfo?.country_id) === 101 &&
                                (userInfo?.companyGroup !== "Fleet" || userInfo?.companyGroup !== "eMSP")) ||
                            false
                        )
                    case "license":
                        return (
                            (currentModule?.license?.view &&
                                (userInfo.companyGroup === "marketplace" ||
                                    userInfo.companyGroup === "CPO" ||
                                    userInfo.companyGroup === "CPO_eMSP")) ||
                            false
                        )
                    case "station-pnl-detail":
                        return (
                            (currentModule?.["PnL"]?.view &&
                                (userInfo?.companyGroup === "marketplace" || parseInt(userInfo?.companyId) === 16)) ||
                            false
                        )

                    case "charger-pnl-detail":
                        return (
                            (currentModule?.["PnL"]?.view &&
                                (userInfo?.companyGroup === "marketplace" || parseInt(userInfo?.companyId) === 16)) ||
                            false
                        )

                    case "pnl":
                        return (
                            (currentModule?.["PnL"]?.view &&
                                (userInfo?.companyGroup === "marketplace" || parseInt(userInfo?.companyId) === 16)) ||
                            false
                        )
                    case "company-invoicing":
                        return (
                            (currentModule?.["company-invoicing"]?.view && userInfo.companyGroup === "marketplace") ||
                            false
                        )
                    default:
                        return "pageNotFound"
                }
            } else return true
        } else {
            return false
        }
    }
    if (modulePath === "complaints") {
        if (allowedModules?.includes("complaints-management")) {
            if (!userRoles?.["complaints-management"]?.view) return false
            if (subModulePath) {
                let currentModule = userRoles?.["complaints-management"]
                switch (subModulePath) {
                    case "overview":
                        return currentModule?.overview?.view || false
                    case "all-complaints":
                        return currentModule?.complaints?.view || false
                    default:
                        return "pageNotFound"
                }
            } else return true
        } else {
            return false
        }
    }
    if (modulePath === "coupon") {
        if (allowedModules?.includes("coupons-management")) {
            let currentModule = userRoles?.["coupons-management"]
            if (!userRoles?.["coupons-management"]?.view) return false
            if (!subModulePath) {
                return currentModule?.view || false
            } else if (subModulePath) {
                switch (subModulePath) {
                    case "add-coupon":
                        return currentModule?.coupons?.add || false
                    case "coupon-table":
                        return currentModule?.view || false
                    default:
                        return "pageNotFound"
                }
            } else return true
        } else {
            return false
        }
    }
    if (modulePath === "reports") {
        if (allowedModules?.includes("reports")) {
            if (!userRoles?.["reports"]?.view) return false
            let currentModule = userRoles?.["reports"]
            return (
                (currentModule?.view &&
                    (userInfo?.companyGroup === "marketplace" || parseInt(userInfo?.companyId) === 16)) ||
                false
            )
        } else {
            return false
        }
    }
    if (modulePath === "auto-trigger") {
        if (allowedModules?.includes("auto-trigger")) {
            if (!userRoles?.["auto-trigger"]?.view) return false
            if (subModulePath) {
                let currentModule = userRoles?.["auto-trigger"]
                switch (subModulePath) {
                    case "alerts":
                        return currentModule?.view || false
                    case "preferences":
                        return (
                            (currentModule?.view &&
                                (currentModule?.["auto-trigger"]?.add || currentModule?.["auto-trigger"]?.edit)) ||
                            false
                        )
                    case "trigger-details":
                        return currentModule?.view || false
                    default:
                        return "pageNotFound"
                }
            } else return true
        }
    }
    if (modulePath === "profile") return true
    else {
        return false
    }
}

const CmsLayout = props => {
    const navigate = useNavigate()
    const location = useLocation()

    const isLoggedIn = getItem("isUserLoggedIn")
    const { userInfo, userRoles } = useSelector(store => store.auth)
    const [isPageNotFound, setIsPageNotFound] = useState("")

    useEffect(() => {
        const dashboardHiddenCompanyIds = process.env.REACT_APP_DASHBOARD_HIDDEN_COMPANIES || []
        if (
            dashboardHiddenCompanyIds?.length &&
            dashboardHiddenCompanyIds.includes(userInfo?.companyId) &&
            location.pathname === "/"
        ) {
            navigate("/complaints/all-complaints", { replace: true })
        }
    }, [location])

    useEffect(() => {
        const res = checkIsUserValid(userInfo, userRoles, location.pathname)
        setIsPageNotFound(res)
    }, [userInfo, userRoles, location])

    return isLoggedIn ? (
        isPageNotFound === "pageNotFound" ? (
            <PageNotFound />
        ) : (
            <>
                <Header {...{ userInfo, userRoles }} />
                {checkIsUserValid(userInfo, userRoles, location.pathname) ? props.children : <UnauthorisedAccess />}
            </>
        )
    ) : (
        <Navigate to="/auth" replace />
    )
}

export default CmsLayout
